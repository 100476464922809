import { ApplicationInsights, ITelemetryItem, SeverityLevel } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";

import { ComponentType } from "react";
import { ILogger } from "oidc-client-ts";
import { Metric } from "web-vitals";
import { UserState } from "core/state/userState";

interface DeviceDetails {
  Platform: string;
  "App-Version"?: string;
  "Device-Info"?: string;
}

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
    loggingLevelConsole: 2,
  },
});

const appInsightsLogger: ILogger = {
  debug(...args: unknown[]): void {
    appInsights.trackTrace({ severityLevel: SeverityLevel.Verbose, message: argsToString(args) });
    if (isLocalHost()) {
      console.debug(args);
    }
  },

  info(...args: unknown[]): void {
    appInsights.trackTrace({ severityLevel: SeverityLevel.Information, message: argsToString(args) });
    if (isLocalHost()) {
      console.info(args);
    }
  },

  warn(...args: unknown[]): void {
    appInsights.trackTrace({ severityLevel: SeverityLevel.Warning, message: argsToString(args) });
    if (isLocalHost()) {
      console.warn(args);
    }
  },

  error(message: string, err?: any): void {
    appInsights.trackException({ severityLevel: SeverityLevel.Error, exception: err }, { message: message });
    if (isLocalHost()) {
      console.error(message);
    }
  },
};

const argsToString = (...args: unknown[]): string => "".concat(...args.map((arg) => String(arg)));
const isLocalHost = (): boolean => ["localhost", "127.0.0.1", "::1", "10.0.2.2"].includes(window.location.hostname);

function initialiseAppInsights(deviceDetails?: DeviceDetails) {
  appInsights.loadAppInsights();
  const telemetryInitialiser = (envelope: ITelemetryItem) => {
    envelope.data = { wisdomUserId: UserState.get()?.id, ...(deviceDetails && { ...deviceDetails, "Background-Action": "In App" }) };
    return true;
  };
  appInsights.addTelemetryInitializer(telemetryInitialiser);
}

const withAppInsights = (component: ComponentType<unknown>) => withAITracking(reactPlugin, component);

const nameMap = new Map([
  ["FCP", "First Contentful Paint"],
  ["TTFB", "Time to First Byte"],
  ["TBT", "Total Blocking Time"],
  ["TTI", "Time to Interactive"],
  ["LCP", "Largest Contentful Paint"],
  ["INP", "Interaction To Next Paint"],
  ["CLS", "Cumulative Layout Shift"],
]);

function sendToAppInsights(metric: Metric) {
  const properties = {
    eventCategory: "Web Vitals",
    eventAction: metric.name,
    eventDescription: nameMap.get(metric.name),
    eventLabel: metric.id,
  };
  const metricTelemetry = {
    name: `WebVitals.${metric.name}`,
    average: Math.round(metric.name === "CLS" ? metric.delta * 1000 : metric.delta),
    properties,
  };
  reactPlugin.trackMetric(metricTelemetry, properties);
}

export { reactPlugin, appInsights, withAppInsights, sendToAppInsights, initialiseAppInsights, appInsightsLogger };
