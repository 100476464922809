import { DatoContent } from "../DatoContent/DatoContent";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { useGetPublicPageFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import useLocale from "core/hooks/useLocale/useLocale";
import { useTranslation } from "react-i18next";

type DatoContentPageProps = {
  slug: string | undefined;
};

export const DatoContentPage = ({ slug }: DatoContentPageProps) => {
  const { localeId } = useLocale();
  const { t } = useTranslation();
  const { data, isLoading, error, mutate } = useGetPublicPageFunctionRequest(
    {
      slug: slug,
      localeId,
    },
    { needsBearerToken: false, revalidateOnMountIfStale: false, showErrorIfStale: false }
  );

  return (
    <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("datoContentPage.error")} onRetry={mutate} isFullScreenView={true}>
      <div className="break-words">
        <DatoContent content={data?.content} />
      </div>
    </ViewStateWrapper>
  );
};
