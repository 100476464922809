import CallUsBlock from "./Components/CallUsBlock/CallUsBlock";
import { ReactComponent as CallbackIcon } from "shared/assets/Icons/request-contact.svg";
import { ClickableRoundedCard } from "./Components/ContactCard/ClickableRoundedCard";
import { ContactCardWithButton } from "./Components/ContactCard/ContactCardWithButton";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { ReactComponent as LiveChatIcon } from "shared/assets/Icons/contact-us.svg";
import { ReactComponent as LiveChatUnavailableIcon } from "shared/assets/Icons/contact-us-unavailable.svg";
import { ProtectedModule } from "core/auth/ProtectedModule";
import { ReportingEventType } from "shared/constants/ReportingEventType";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { SafeSpaces } from "../Home/components/SafeSpaces/SafeSpaces";
import { UserState } from "core/state/userState";
import { ReactComponent as VideoCallIcon } from "shared/assets/Icons/video-call.svg";
import { ReactComponent as VideoCallUnavailableIcon } from "shared/assets/Icons/video-call-unavailable.svg";
import { WisdomButtonCard } from "./Components/WisdomButtonCard";
import WisdomModules from "core/Modules";
import { dial } from "core/javascriptChannels";
import helplineNumbersJson from "shared/assets/json/helplineNumbers.json";
import { isAU } from "shared/core/locale";
import { removeSpaces } from "shared/core/utils/string";
import { t } from "i18next";
import { useLiveChat } from "./Components/LiveChatProvider";
import useLocale from "core/hooks/useLocale/useLocale";
import { useLogReportingEventFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useMobilePlatform } from "core/hooks/useMobilePlatform";
import { useNavigate } from "react-router-dom";
import { useVideoCall } from "./hooks/useVideoCall";

type HelplineNumber = { country: string; number: string };

const helplineNumbers: HelplineNumber[] = helplineNumbersJson;

const parseHelplineNumbers = (inputString: string): HelplineNumber[] => {
  const parsedNumbers: HelplineNumber[] = [];
  const normalizedInput = removeSpaces(inputString);

  for (const { number, country } of helplineNumbers) {
    const normalizedNumber = removeSpaces(number);
    const regex = new RegExp(normalizedNumber, "g");

    if (regex.test(normalizedInput)) {
      parsedNumbers.push({ country, number });
      inputString = inputString.split(regex).join("");
    }
  }

  return parsedNumbers;
};

export const ContactUs = () => {
  const user = UserState.get();
  const helplineData = user?.helplinePhoneNumber ?? "";
  const parsedHelplineNumbers = parseHelplineNumbers(helplineData);
  const navigate = useNavigate();
  const { trigger } = useLogReportingEventFunctionRequest();
  const { isChatAvailable, startChat } = useLiveChat();
  const { isVideoCallAvailable, startVideoCall } = useVideoCall();
  const { isMobileApp } = useMobilePlatform();
  const { localeId } = useLocale();

  return (
    <div className="mt-6">
      <h2>{t("contactUs.heading")}</h2>
      <p className="col-start-1">{t("contactUs.headingParagraph")}</p>
      <div className="flex flex-row flex-wrap">
        <div className="flex flex-col w-full md:flex-row">
          <Hideable hidden={isAU(localeId)}>
            <div className="w-full py-4 md:p-4">
              <ClickableRoundedCard
                title={t("contactUs.liveChat.title")}
                icon={isChatAvailable ? <LiveChatIcon /> : <LiveChatUnavailableIcon />}
                description={isChatAvailable ? t("contactUs.liveChat.description") : t("contactUs.liveChat.descriptionOffline")}
                disabled={!isChatAvailable}
                showChevron={isChatAvailable}
                handleClick={() => trigger({ body: { reportingEventType: ReportingEventType.SupportRequestLiveChat } }).finally(() => startChat())}
              />
            </div>
          </Hideable>
          <Hideable hidden={isAU(localeId)}>
            <ProtectedModule
              wisdomModule={WisdomModules.videoChat}
              moduleComponent={
                <div className="w-full py-4 md:p-4">
                  <ClickableRoundedCard
                    title={t("contactUs.videoCall.title")}
                    icon={isVideoCallAvailable ? <VideoCallIcon /> : <VideoCallUnavailableIcon />}
                    description={isVideoCallAvailable ? t("contactUs.videoCall.description") : t("contactUs.videoCall.descriptionOffline")}
                    handleClick={() => trigger({ body: { reportingEventType: ReportingEventType.SupportRequestVideoCall } }).finally(startVideoCall)}
                    showChevron={isVideoCallAvailable}
                    disabled={!isVideoCallAvailable}
                  />
                </div>
              }
            />
          </Hideable>
        </div>

        {parsedHelplineNumbers && parsedHelplineNumbers.length > 0 && (
          <div className="w-full py-4 md:p-4 md:w-1/2">
            <h4 className="ml-5 font-semibold text-black">{t("contactUs.callUs.description")}</h4>
            <div className="flex flex-col gap-4">
              {parsedHelplineNumbers.map(({ number, country }: HelplineNumber, index: number) => {
                return (
                  number && (
                    <RoundedCard key={index}>
                      <button
                        onClick={
                          isMobileApp
                            ? () =>
                                trigger({ body: { reportingEventType: ReportingEventType.SupportRequestPhone } }).finally(() =>
                                  dial(removeSpaces(number))
                                )
                            : undefined
                        }
                        disabled={!isMobileApp}
                        className="flex items-center"
                      >
                        <CallUsBlock code={country} number={number} />
                      </button>
                    </RoundedCard>
                  )
                );
              })}
            </div>
          </div>
        )}
        <div className="w-full py-4 md:p-4 md:w-1/2">
          <ContactCardWithButton
            buttonText={t("contactUs.callback.title")}
            buttonColour="primary"
            handleClick={() =>
              trigger({ body: { reportingEventType: ReportingEventType.SupportRequestRequestCallback } }).finally(() =>
                navigate("/contact-us/callback")
              )
            }
            icon={<CallbackIcon />}
            description={t("contactUs.callback.description")}
          />
        </div>
      </div>
      <Hideable hidden={isAU(localeId)}>
        <div>
          <div className="w-full py-4 md:p-4 md:w-1/2">
            <WisdomButtonCard />
          </div>
          <div className="w-full py-4 md:p-4 md:w-1/2">
            <SafeSpaces />
          </div>
        </div>
      </Hideable>
    </div>
  );
};
