import { Controller, useForm } from "react-hook-form";
import { RequestCallbackCommand, useRequestCallbackFunctionRequest } from "shared/request/myHealthyAdvantageApi";

import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { Select } from "shared/UI/Inputs/Select/Select";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { UserContext } from "core/state/UserContext";
import { ValidatableInput } from "shared/UI/Inputs/Input/ValidatableInput";
import { ValidatableWrapper } from "shared/UI/Inputs/ValidatableWrapper";
import { isAU } from "shared/core/locale";
import { phoneNumberRegex } from "shared/utils/regex";
import { t } from "i18next";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useContext } from "react";
import useLocale from "core/hooks/useLocale/useLocale";

type Inputs = {
  phoneNumber: string;
  reasonForCalling: string;
  leaveVoicemail: string;
  canSms: string;
  callbackTime: string;
};
export const Callback = () => {
  const { trigger: requestCallbackAPI } = useRequestCallbackFunctionRequest();
  const { user } = useContext(UserContext);

  const { localeId } = useLocale();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, defaultValues },
  } = useForm<Inputs>({
    defaultValues: {
      phoneNumber: user?.phoneNumber ?? "",
      reasonForCalling: "",
      leaveVoicemail: "",
      canSms: "",
      callbackTime: "",
    },
  });

  const onSubmit = async (inputs: Inputs) => {
    const data: RequestCallbackCommand = {
      phoneNumber: inputs.phoneNumber,
      reasonForCalling: inputs.reasonForCalling,
      leaveVoicemail: inputs.leaveVoicemail,
      canSendSms: inputs.canSms,
      callbackTime: inputs.callbackTime,
    };
    try {
      await requestCallbackAPI({ body: data });
      reset();
      toastNotify(t("contactUs.callback.requestSuccessMessage"), "success");
    } catch {
      toastNotify(t("contactUs.callback.requestErrorMessage"), "error");
    }
  };

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("contactUs.callback.title")} backRoute={"../contact-us"} />
      </CurvedTopBanner>
      <p className={"mb-4"}>{t("contactUs.callback.description")}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ValidatableInput
          validationMessage={t("contactUs.callback.phoneNumberErrorMessage")}
          validationState={errors.phoneNumber ? "error" : undefined}
          type="tel"
          defaultValue={defaultValues?.phoneNumber}
          label={t("contactUs.callback.phoneNumber")}
          {...register("phoneNumber", { required: true, pattern: phoneNumberRegex })}
        />

        <Controller
          name={"reasonForCalling"}
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <ValidatableWrapper
                validationMessage={t("contactUs.callback.reasonForCallingErrorMessage")}
                validationState={errors.reasonForCalling ? "error" : undefined}
              >
                <Select id="reasonForCalling" label={t("contactUs.callback.reasonForCalling")} onChange={onChange} value={value}>
                  <option key="default">{t("contactUs.callback.reasonForCalling")}</option>
                  <option key="counselling" value="Counselling">
                    {t("contactUs.callback.reasonForCallingOptions.counselling")}
                  </option>
                  <Hideable hidden={isAU(localeId)}>
                    <option key="legalInformation" value="Legal Information">
                      {t("contactUs.callback.reasonForCallingOptions.legalInformation")}
                    </option>
                    <option key="financialWellbeing" value="Financial Wellbeing">
                      {t("contactUs.callback.reasonForCallingOptions.financialWellbeing")}
                    </option>
                  </Hideable>
                  <option key="clientServices" value="Client Services">
                    {t("contactUs.callback.reasonForCallingOptions.clientServices")}
                  </option>
                </Select>
              </ValidatableWrapper>
            </div>
          )}
        />

        <div className="my-4">
          <label>{t("contactUs.callback.leaveVoicemail")}</label>
          <ValidatableWrapper
            validationMessage={t("contactUs.callback.requiredErrorMessage")}
            validationState={errors.leaveVoicemail ? "error" : undefined}
          >
            <div className="flex items-center justify-between w-2/5 lg:w-1/5">
              <div>
                <input type="radio" id="leaveVoicemailYes" value="yes" className="mr-1" {...register("leaveVoicemail", { required: true })} />
                <label htmlFor="leaveVoicemailYes">{t("contactUs.callback.yes")}</label>
              </div>

              <div>
                <input type="radio" id="leaveVoicemailNo" value="no" className="mr-1" {...register("leaveVoicemail", { required: true })} />
                <label htmlFor="leaveVoicemailNo">{t("contactUs.callback.no")}</label>
              </div>
            </div>
          </ValidatableWrapper>
        </div>

        <div className="my-4">
          <label>{t("contactUs.callback.canSms")}</label>
          <ValidatableWrapper validationMessage={t("contactUs.callback.requiredErrorMessage")} validationState={errors.canSms ? "error" : undefined}>
            <div className="flex items-center justify-between w-2/5 lg:w-1/5">
              <div>
                <input type="radio" id="canSmsYes" value="yes" className="mr-1" {...register("canSms", { required: true })} />
                <label htmlFor="canSmsYes">{t("contactUs.callback.yes")}</label>
              </div>

              <div>
                <input type="radio" id="canSmsNo" value="no" className="mr-1" {...register("canSms", { required: true })} />
                <label htmlFor="canSmsNo">{t("contactUs.callback.no")}</label>
              </div>
            </div>
          </ValidatableWrapper>
        </div>

        <div className="my-4">
          <label>{t("contactUs.callback.callbackTime")}</label>
          <ValidatableWrapper
            validationMessage={t("contactUs.callback.requiredErrorMessage")}
            validationState={errors.callbackTime ? "error" : undefined}
          >
            <div className="flex items-center justify-between w-2/5 lg:w-1/5">
              <div>
                <input type="radio" id="callbackTimeAm" value="AM" className="mr-1" {...register("callbackTime", { required: true })} />
                <label htmlFor="callbackTimeAm">{t("contactUs.callback.am")}</label>
              </div>

              <div>
                <input type="radio" id="callbackTimePm" value="PM" className="mr-1" {...register("callbackTime", { required: true })} />
                <label htmlFor="callbackTimePm">{t("contactUs.callback.pm")}</label>
              </div>
            </div>
          </ValidatableWrapper>
        </div>

        <StylableButton
          className="bright-button"
          fullWidth
          color="primary"
          type="submit"
          text={t("contactUs.callback.requestCallback")}
          aria-label={t("contactUs.callback.requestCallback")}
        />
      </form>
    </>
  );
};
