import BrightExchangeLogo from "shared/assets/BrightExchangeLogo.jpg";
import { CardWithButton } from "UIPalette/CardWithButton/CardWithButton";
import { externalLink } from "core/links";
import { isAU } from "shared/core/locale";
import { t } from "i18next";
import useLocale from "core/hooks/useLocale/useLocale";

export const convertToAU = (url: string): string => {
  try {
    const u = new URL(url);
    u.pathname = !u.pathname.startsWith("/au/") ? "/au" + u.pathname : u.pathname;
    return u.toString();
  } catch (e) {
    return url;
  }
};

export const PerksAndDiscountsCard = () => {
  const exchangeUri = process.env.REACT_APP_PERKS_AND_DISCOUNTS_URI!;
  const { localeId } = useLocale();
  const externalUri = !isAU(localeId) ? exchangeUri : convertToAU(exchangeUri);
  return (
    <CardWithButton buttonClassName="orange-button" buttonText={t("home.perksAndDiscounts.button")} handleClick={() => externalLink(externalUri)}>
      <h2 className="pb-3 text-center">{t("home.perksAndDiscounts.title")}</h2>
      <img className="mx-auto max-w-[240px]" src={BrightExchangeLogo} alt="Bright Exchange Logo" />
      <p className="pt-3 text-center" role="paragraph">
        {t("home.perksAndDiscounts.description")}
      </p>
    </CardWithButton>
  );
};
