type JavascriptChannel = { callHandler: (...params: any[]) => Promise<any> };

export const dial = (number: string) => window.flutter_inappwebview?.callHandler("dial", number);

export const copyToClipboard = (text: string) => window.flutter_inappwebview?.callHandler("copyToClipboard", text);

export const getMobilePlatfrom = async (): Promise<string> => (await window.flutter_inappwebview?.callHandler("getPlatform"))?.toLowerCase();

export const share = (fileUrl: string, fileName: string) => window.flutter_inappwebview?.callHandler("shareFile", fileUrl, fileName);

export const loadAudio = ({ src, title }: { src: string; title: string }): Promise<AudioPlayerState | undefined> | undefined =>
  window.flutter_inappwebview?.callHandler("loadAudio", src, title);

export const playAudio = () => window.flutter_inappwebview?.callHandler("playAudio");

export const pauseAudio = () => window.flutter_inappwebview?.callHandler("pauseAudio");

export const seekAudio = (position: number) => window.flutter_inappwebview?.callHandler("seekAudio", position);

export const setAudioSeekState = (isSeeking: boolean) => window.flutter_inappwebview?.callHandler("setAudioSeekState", isSeeking);

export const authenticateMobileUser = (clientCode: string, userObject: string) =>
  window.flutter_inappwebview?.callHandler("authentication", clientCode, userObject);

export const flutterAppOpen = async (): Promise<string> => await window.flutter_inappwebview?.callHandler("flutterAppOpen");

export const getAppVersion = async (): Promise<string> => await window.flutter_inappwebview?.callHandler("getAppVersion");

export const getDeviceInfo = async (): Promise<string> => await window.flutter_inappwebview?.callHandler("getDeviceInfo");

export type AudioPlayerState = {
  isPlaying: boolean;
  durationInSeconds: number;
  positionInSeconds: number;
};

declare global {
  interface Window {
    flutter_inappwebview?: JavascriptChannel;
    updateAudioProgress?: (progress: number) => void;
    backgroundAudioEnabled?: boolean;
  }
}
